import React, { createContext, useMemo, useReducer, useEffect } from 'react';
import { config, faqs, benefits, footer, founders, areyoucreatorinfo,roadmap } from '../services/sanity/sanity';
import { sanityReducer, initialState } from '../web3/reducers/sanityReducer';
import { LOADING_SANITY_TYPE, GET_SANITY_DATA_TYPE, ERROR_SANITY_TYPE } from '../web3/types/types';
import PropTypes from 'prop-types';

export const SanityContext = createContext(null);

const SanityProvider = ({ children }) => {
    const [sanity, dispatch] = useReducer(sanityReducer, initialState);
    useEffect(() => {
        Promise.all([config(), founders(), benefits(), faqs(), footer(), areyoucreatorinfo(), roadmap()])
            .then(([configData, foundersData, benefitsData, faqsData, footerData, areyoucreatorData, roadmapData]) => {
                dispatch({ type: GET_SANITY_DATA_TYPE, payload: { configData, foundersData, benefitsData, faqsData, footerData, areyoucreatorData, roadmapData} });
                //console.info('Sanity data is loaded', configData, foundersData);
            })
            .catch((error) => {
                console.error('Error loading Sanity data:', error);
                dispatch({ type: ERROR_SANITY_TYPE, payload: error });
            })
            .finally(() => {
                dispatch({ type: LOADING_SANITY_TYPE, payload: false });
            });
    }, []);
    const sanityData = useMemo(() => ({ sanity }), [sanity]);
    return (
        <SanityContext.Provider value={sanityData}>
            {children}
        </SanityContext.Provider>
    );
};

SanityProvider.propTypes = {
    children: PropTypes.node
};

export default SanityProvider;
