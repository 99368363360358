import React ,{ useContext, useState, useEffect } from 'react';
import FAQItem from "./FAQItem";
import styles from "./FAQs.module.css";
import { SanityContext } from "../context/SanityProvider";

const FAQs = () => {
  const { sanity } = useContext(SanityContext);
  const [ faqs, setFaqs ] = useState([]);
  const [ title, setTitle ] = useState('');

  useEffect(()=>{
    if(sanity && sanity.sanity && sanity.sanity.faqsData && sanity.sanity.faqsData.length > 0 && sanity.sanity.faqsData[0] != null){
      setTitle(sanity.sanity.faqsData[0].title);
      if(sanity.sanity.faqsData[0] && sanity.sanity.faqsData[0].faqs && sanity.sanity.faqsData[0].faqs.length > 0){
        setFaqs(sanity.sanity.faqsData[0].faqs)
     
      }
    }
  },[sanity]);
  
  return (
    <div className={styles.faqs}>
      <b id="faqs" className={styles.section}>
        {title}
      </b>
      {
        faqs.map((item,index)=>{
          return (
            <FAQItem
              key={index}
              question={item.title}
              answer={item.description}
            />
          )
        })
      }
    </div>
  );
};

export default FAQs;
