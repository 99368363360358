import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Error = ({ error }) => {
    if (typeof error === 'string') 
        return (
            <Box display="flex" justifyContent="center" color="#dc3545" paddingBottom={2}>
                <h3 style={{ marginTop: '0px', marginBottom: '0px' }}>{error}</h3>
            </Box>
        );
    return (
        <Box display="flex" justifyContent="center" color="#dc3545" paddingBottom={2}>
            <h3 style={{ marginTop: '0px', marginBottom: '0px' }}>
                {error && error.response && error.response.data && error.response.data.message ? (
                    <React.Fragment>Error: {error.response.data.message}</React.Fragment>
                ) : (
                    <React.Fragment>
                        {error &&
                        error.message &&
                        String(error.message + '').includes('status code 500') ? (
                            'Intentlo de nuevo mas tarde'
                        ) : (
                            <React.Fragment>
                                {error && error.message && error.message}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </h3>
        </Box>
    );
};

Error.propTypes = {
    error: PropTypes.any
};

export default Error;
