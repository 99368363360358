import React, { useContext } from 'react';
import { Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import styles from "./PanelTermsAndConditions.module.css";
import { SanityContext } from '../context/SanityProvider';
import Block from '../components/Block';
import PropTypes from 'prop-types';

const PanelTermsAndConditions = ({ setOpenSelectedTypePay }) => {

    const [ activeBtn, setActiveBtn ] = React.useState(false);
    const { sanity } = useContext(SanityContext);

    const handleClickOpenGamePass = () => {
        setOpenSelectedTypePay(3);
    }

    if (!sanity.sanity.configData.termsAndConditions) return (<div></div>);
    if (sanity.sanity?.configData?.termsAndConditions) {
        return (
            <div  className={styles.panel}>
                <center>
                    <div className={styles.title}>Terms of Service & Conditions</div>
                    <div className={styles.paragraph}>
                        <Block text={sanity.sanity.configData.termsAndConditions.description} padding="0px 2%" />
                        <div className={styles.checkboxWrapper}>
                            <AcceptTerminsAndConditionCheckbox checked={activeBtn} onChange={(event) => setActiveBtn(event.target.checked)}/>
                        </div>
                    </div>
                    <div className={styles.contentButtons} style={{marginTop: '15px'}}>
                        <button 
                            disabled={!activeBtn}
                            onClick={handleClickOpenGamePass}
                            className={!activeBtn ? styles.disabledBtn : styles.buttonCustom} 
                        >
                            <img className={styles.Icon} alt="" src={'../wallet-alt.svg'} />
                            <b className={styles.text} >
                                PROCEED TO MINT
                            </b>
                        </button>
                    </div>
                </center>
            </div>
        );
    }
};

const AcceptTerminsAndConditionCheckbox = (props) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked/>}
                        {...props}/>
                }
                label={
                    <Typography variant="body1" style={{ fontFamily: 'Arial', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Inconsolata' }}>
                        I accept the Terms of Service and Conditions above
                    </Typography>
                }/>
        </FormGroup>
    );
}

PanelTermsAndConditions.propTypes = {
    setOpenSelectedTypePay: PropTypes.func
};

export default PanelTermsAndConditions;