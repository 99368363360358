import React from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';

const BlockSanity = ({text, styleClass}) => {
    return (
        <BlockContent
            className={styleClass}
            blocks={text}
        />
    );
};

BlockSanity.propTypes = {
    text: PropTypes.any,
    styleClass: PropTypes.any
};


export default BlockSanity;