import React, { createContext, useMemo } from 'react';
import useFetch from '../hooks/useFetch';
import PropTypes from 'prop-types';
import usePrice from '../hooks/usePrice';

export const PerseaContext = createContext(null);

const PerseaProvider = ({ children }) => {
    const urlCollection = `${process.env.REACT_APP_URL_API}project?domain=${process.env.REACT_APP_DOMAIN}&limit=300&page=0&order=created`;
    // const urlCollection = `${process.env.REACT_APP_URL_API}project?domain=${process.env.REACT_APP_DOMAIN}&address=0XA27402ECDB036CC293B7C1CF36030079472CE9EC&limit=300&page=0&order=created`;
    const { data: collection, error: collectionError, isLoading: collectionLoading } = useFetch(urlCollection, {}, (data) => getFirstCollectionWithRandomNFT(data));
    const {price, isLoading: priceLoading, error: priceError} = usePrice(collection);
    const nft = collection ?  collection.random_nft : null;
    const values = useMemo(() => {
        return {
            collection,
            collectionError,
            collectionLoading,
            price,
            priceLoading,
            priceError,
            nft
        };
    }, [
        collection,
        collectionError,
        collectionLoading,
        price,
        priceLoading,
        priceError,
        nft
    ]);
    return (
        <PerseaContext.Provider value={values}>
            {children}
        </PerseaContext.Provider>
    );
};

const getFirstCollectionWithRandomNFT = (collections) => {
    for (const collection of collections) {
        if (collection.random_nft) return collection;
    }
    return collections[0];
}

PerseaProvider.propTypes = {
    children: PropTypes.node
};

export default PerseaProvider;
