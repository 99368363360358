import styles from "./NFTPreview.module.css";

const NFTPreview = ({ image }) => {
  return (
    <div className={styles.nftpreview}>
      <img className={styles.img} alt="" src={image} />
    </div>
  );
};

export default NFTPreview;
