import React, {  useContext, useState, useEffect }  from 'react';
import DownloadWhitepaperButton from "./Buttons/DownloadWhitepaperButton";
import { Typography, Box, CardMedia, Grid, ListItem, ListItemAvatar, ListItemText, Container } from '@mui/material';
import { SanityContext } from "../context/SanityProvider";

const Benefits = () => {
  const { sanity } = useContext(SanityContext);
  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');
  // const [ urlWhitePaper, setUrlWhitePaper ] = useState('');
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    if(sanity && sanity.sanity && sanity.sanity.benefitsData){
        if(sanity.sanity.benefitsData && sanity.sanity.benefitsData.title){
          setTitle(sanity.sanity.benefitsData.title);
        }
        if(sanity.sanity.benefitsData && sanity.sanity.benefitsData.description){
          setDescription(sanity.sanity.benefitsData.description);
        }
        // if(sanity.sanity.benefitsData && sanity.sanity.benefitsData.whitepaper){
        //   setUrlWhitePaper(sanity.sanity.benefitsData.whitepaper)
        // }
        if(sanity.sanity.benefitsData && sanity.sanity.benefitsData.items && sanity.sanity.benefitsData.items.length > 0){
          setItems(sanity.sanity.benefitsData.items);
        }
    }
  },[sanity]);

  return (
    <Container
      maxWidth="xl"
      id="benefits"
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        padding: '380px 0px 0px',
        color: '#000',
        '@media (max-width: 1100px)': {
          flexDirection: 'column',
          alignItems: 'center',
          padding: '50px 20px'
        }
        //display="flex" justifyContent="center" alignItems="center" color="#000" margin="150px auto" gap={10}
      }}
    >
      <Box
        width="90%"
        display="flex"
        flexDirection="column"
        gap="24px"
        sx={{
          '@media (max-width: 1100px)': {
            width: '100%',
            alignItems: 'center',
            mb: 5
          }
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '44px',
              lineHeight: '56px',
              fontFamily: 'Space Grotesk',
              '@media (max-width: 1100px)': {
                textAlign: 'center',
                fontSize: '24px',
                lineHeight: '31px'
              }
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '25px',
              fontFamily: 'Inconsolata',
              '@media (max-width: 1100px)': {
                textAlign: 'center',
                fontSize: '24px',
                lineHeight: '25px'
              }
            }}
          >
            {description} 
          </Typography>
        </Box>
        <Box>
          <DownloadWhitepaperButton text={sanity.sanity?.configData?.homeButtonBlackLabel} />
        </Box>
      </Box>
        <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          {
            items?.map((item,index)=>{
              return(
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                  <ListItem
                    sx={{
                      '&,span':{
                        '@media (max-width: 1100px)': {
                          fontSize: '20px',
                          lineHeight: '25px'
                        }
                      },
                      '&,p':{
                        '@media (max-width: 1100px)': {
                          fontSize: '20px',
                          lineHeight: '20px'
                        }
                      }  
                    }}
                  >
                    <ListItemAvatar>
                      <CardMedia component="img" src={item.image} alt="image" title="roadmap" sx={{ width: '72px', height: '72px', mr: 2 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={item.description}
                      primaryTypographyProps={{
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '31px',
                        fontFamily: 'Space Grotesk'
                      }}
                      secondaryTypographyProps={{
                        fontWeight: 400,
                        fontSize: '24px',
                        lineHeight: '25px',
                        fontFamily: 'Inconsolata'
                      }}
                    />
                      
                  </ListItem>
                </Grid>
              )
            })
          }
         
        </Grid>
    </Container>
  );
};

export default Benefits;
