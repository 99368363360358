import { useEffect, useContext, Fragment, useState, useRef } from "react";
import Social from "./social/Social";
import BrandTitle from "../components/BrandTitle";
import NFTPreview from "../components/NFTPreview";
import Partners from "../components/Partners";
import WhitelistCountdown from "../components/WhitelistCountdown";
import styles from "./Section.module.css";
import BlackButton from "./Buttons/BlackButton";
import DownloadWhitepaperButton from "./Buttons/DownloadWhitepaperButton";
import CountDown from 'react-countdown';
import { Web3Context } from '../context/Web3AuthContext';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { SanityContext } from "../context/SanityProvider";
import PropTypes from 'prop-types';
import { totalSupply } from "../services/blockchain";
import { PerseaContext } from "../context/PerseaProvider";

const StyledContainer = styled(Box)`
  //display="flex" justifyContent="center" alignItems="center" gap={4}
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  @media (max-width: 1100px) {
    flex-direction: column; 
  }
`;

const StyledContent = styled(Box)`
  //display="flex" flexDirection="column" gap="25px" width="75%"
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 75%;
  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const StyledTitle = styled(Box)`
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  font-family: 'Space Grotesk';
  font-style: normal;
  @media (max-width: 1500px) {
    font-size: 24px;
    font-style: normal;
    line-height: 31px;
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const StyledSubtitle = styled(Box)`
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  font-family: 'Inconsolata';
  font-style: normal;
  @media (max-width: 1500px) {
    font-size: 14px;
    font-style: normal;
    font-style: normal;
    line-height: 18px;
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const Section = ({ setOpenModalPaymentMobile, setOpenModalPaymentDesktop, setOpenSelectedTypePay }) => {
  const { state } = useContext(Web3Context);
  const { sanity } = useContext(SanityContext);
  const { collection } = useContext(PerseaContext);
  const interval = useRef();
  const [ currentSale, setCurrentSale ] = useState(0)
  const maxSale = process.env.REACT_APP_MAX_SALE;
  const timeInterval = process.env.REACT_APP_INTERVAL_SOLDOUT;
  const [ soldOut, setSoldOut ] = useState(false);
  console.log('maxSale',maxSale)
  const getTotal = async() => {
    if(collection && collection.project_key && collection.blockchain && collection.blockchain.alternative_rpc){
      const total = await totalSupply(null,collection.project_key,collection.blockchain.alternative_rpc); 
      setCurrentSale(total);
      if(total >= parseInt(maxSale)){
        setSoldOut(true);
      }else{
        setSoldOut(false);
      }
    }
  }
  const verifySoldOut =  () => {
    try{ 
      clearInterval(interval.current);
      interval.current = setInterval(()=> {
        if(currentSale >= maxSale){
          clearInterval(interval.current);
          interval.current = null;
          return interval.current;
        }else{
          getTotal();
        }
      },timeInterval);
    }catch(e){
      console.log(e);
    }
  }

  useEffect(()=> {
    getTotal();
    if(timeInterval){
      verifySoldOut();
    }
  }, [collection]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const handleClickFunctionType = (completed) => {
    console.log("completed to open modal", completed);
    if (completed) {
      setOpenSelectedTypePay(1);
      if (window.screen.width <= 768) {
        console.log('screen width <= 768')
        setOpenModalPaymentMobile(true);
      } else {
        setOpenModalPaymentDesktop(true);
      }
    } else {
      window.open(`${sanity.sanity.configData.whitelistURL}`, "_blank");
    }
  };
  
  const foceFinish = process.env.REACT_APP_FORCE_COUNTER;
  let counter = (foceFinish) ? Date.now() : Date.now() + (new Date(`${sanity.sanity.configData.releaseDate}`) - new Date());
  return (
    <div className={styles.frameWrapper} data-animate-on-scroll>
      <div className={styles.background}>
        <div className={styles.socialParent}>
          <Social sanity={sanity.sanity && sanity.sanity.footerData?.icons} />
          <div className={styles.brandtitle}>
            <BrandTitle />
          </div>
          <StyledContainer>
            <Box flex={1}>
              {sanity.sanity && <NFTPreview image = {sanity.sanity.configData?.image} />}
            </Box>
            <Box flex={1}>
              <StyledContent>
                <StyledTitle>
                  {sanity.sanity && sanity.sanity.configData?.title}
                </StyledTitle>
                <StyledSubtitle>
                  {sanity.sanity && sanity.sanity.configData?.description}
                </StyledSubtitle>
                <Partners partners={sanity.sanity && sanity.sanity.configData.icons} />
                {!sanity.loading && sanity.sanity && (
                  <CountDown
                    date={counter}
                    renderer={props => {
                      if (props.completed) {
                        console.log("sanity", sanity.sanity.releaseDate);
                        return (
                          <>
                          <div className={styles.childrenInner}>
                            <div className={styles.joinwhitelistbuttonParent}>
                              {
                                (soldOut === null || !collection) &&
                                <BlackButton
                                  icon="../wallet-alt4.svg"
                                  text={sanity.sanity.configData.homeButtonWhiteLabel}
                                  className={styles.customButton}
                                  action={() => handleClickFunctionType(false)}
                                />
                              }
                              {
                                soldOut && soldOut !== null && 
                                <BlackButton
                                  text={"SOLDOUT"}
                                  className={styles.customButton}
                                />
                              }
                              {
                                (!soldOut && soldOut !== null && collection && collection.project_key) &&
                                <Fragment>
                                  {!state.loading && (
                                  <BlackButton
                                    icon="../wallet-alt4.svg"
                                    text={"BUY FOUNDER GAME PASS NOW"}
                                    className={styles.customButton}
                                    action={() => handleClickFunctionType(props.completed)}
                                  />
                                  )}
                                  {state.loading && (
                                  <BlackButton
                                    icon="../wallet-alt4.svg"
                                    text={"CONNECTING WALLET..."}
                                    className={styles.customButton}
                                  />
                                  )}
                                </Fragment>
                              }
                              <DownloadWhitepaperButton text={sanity.sanity.configData.homeButtonBlackLabel} className={styles.customButton} />
                            </div>
                          </div>
                        </>
                        )
                        
                      }
                      return (
                        <>
                          {sanity.sanity.configData.showCounter && (<WhitelistCountdown props={props} />)}
                          <div className={styles.childrenInner}>
                            <div className={styles.joinwhitelistbuttonParent}>
                              {!state.loading && (
                                <BlackButton
                                  icon="../wallet-alt4.svg"
                                  text={props.completed ? "BUY FOUNDER GAME PASS NOW" : sanity.sanity.configData.homeButtonWhiteLabel}
                                  className={styles.customButton}
                                  action={() => handleClickFunctionType(props.completed)}
                                />
                              )}
                              {state.loading && (
                                <BlackButton
                                  icon="../wallet-alt4.svg"
                                  text={"CONNECTING WALLET..."}
                                  className={styles.customButton}
                                />
                              )}
                              <DownloadWhitepaperButton text={sanity.sanity.configData.homeButtonBlackLabel} className={styles.customButton} />
                            </div>
                          </div>
                        </>
                      );
                    }}
                  />
                )}
              </StyledContent>
            </Box>
          </StyledContainer>
          <div className={styles.socialRight}>
            <Social sanity={sanity.sanity && sanity.sanity.footerData?.icons} />
          </div>
        </div>
      </div>
    </div>
  );
};

Section.propTypes = {
  toggleDrawerSelectedTypePay: PropTypes.func,
  setOpenModalPaymentDesktop: PropTypes.func,
  setOpenSelectedTypePay: PropTypes.func
};


export default Section;
