import React, { useContext } from 'react';
import FounderItem from "../components/FounderItem";
import { SanityContext } from "../context/SanityProvider";
import styles from "./FoundersSection.module.css";

const FoundersSection = () => {
  const { sanity } = useContext(SanityContext);
  if (sanity.sanity && sanity.sanity.foundersData) {
    return (
      <div className={styles.sectioncreator}>
        <h2 className={styles.title}>FOUNDERS</h2>
        <div className={styles.frameParent}>
            {sanity.sanity.foundersData.map((founder, index) => (
              <FounderItem 
                key={index}
                src={founder.image}
                name={founder.name}
                job={founder.description}
                link={founder.url}
              />
            ))}
            {/* <FounderItem 
              src="../profile_marco_rodriguez.jpeg"
              name="Marco Rodriguez"
              job="Service Design"
              link="https://www.linkedin.com/in/marco-aurelio-b061b694/"/>
            <FounderItem
              src="../profile_juan_carlos_reza.jpeg"
              name="Juan Carlos Reza"
              job="Engine Integrations"
              link="https://www.linkedin.com/in/juan-carlos-reza-79679ba0/"/>
            <FounderItem
              src="../profile_rodrigo_garcia.jpeg"
              name="Rodrigo Garcia"
              job="Legal & Financial"
              link="https://www.linkedin.com/"/> */}
        </div>
      </div>
    );
  }
  if (sanity.error) {
    return <div className={styles.sectioncreator}>error to load sanity</div>;
  }
};

export default FoundersSection;
