import { useEffect, useState } from "react";
import { contractPrice } from "../services/blockchain";

const usePrice = (collection) => {
    const [price, setPrice] =  useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        let ignore = false;
        async function getPrice() {
            setIsLoading(true);
            const project_key = collection.project_key;
            const rpc = collection.blockchain.alternative_rpc;
            try {
                const priceResult = await contractPrice(null, project_key, rpc);
                if (!ignore) {
                    setPrice(priceResult);
                    setError(null);
                }
            } catch (error) {
                if (!ignore) {
                    console.log(error);
                    setError(error);
                }
            } finally {
                if (!ignore) {
                    setIsLoading(false);
                }
            }
        }
        if (collection) {
            getPrice();
        }
        return () => {
            ignore = true;
        }
    }, [collection]);

    return { price, isLoading, error };
}

export default usePrice;