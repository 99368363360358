import React, { useContext } from 'react';
import ItemCreator from "../components/ItemCreator";
import { SanityContext } from "../context/SanityProvider";
import styles from "./CreatorSection.module.css";

const CreatorSection = () => {
  const { sanity } = useContext(SanityContext);
  console.log('from creator sanity', sanity.sanity?.areyoucreatorData);
  console.log('from creator sanity', sanity.sanity);
  const handleClickLinkLaunchpad = (url) => {
    window.open(url, '_blank');
  };
  if (sanity.sanity && sanity.sanity.areyoucreatorData) {
    return (
      <div className={styles.areyoucreator}>
          <div className={styles.sectioncreatortext}>
            <h2 className={styles.areYouA} id="are-you-creator-title">
              {sanity.sanity.areyoucreatorData.title}
            </h2>
            <div className={styles.desktoptext} id="desktop-text">
              <div className={styles.ifYourreAlready}>
                {sanity.sanity.areyoucreatorData.description}
              </div>
              <div
                className={styles.secondary}
                onClick={() => handleClickLinkLaunchpad(sanity.sanity.areyoucreatorData.launchpadFormURL)}
              >
                <img className={styles.cubeIcon} alt="" src="../cube.svg" />
                <b className={styles.actionButton}>FILL LAUNCHPAD FORM</b>
              </div>
            </div>
          </div>
          <div className={styles.sectioncreator} id="section-creator">
            {sanity.sanity.areyoucreatorData.items.map((item, index) => (
              <ItemCreator key={index} item={item} propPadding="0px 0px var(--padding-3xs)" propZIndex="2" />
            ))}
          </div>
      </div>
    );
  }
  if (sanity.error) {
    return <div className={styles.areyoucreator}>error to load sanity</div>;
  }
};

export default CreatorSection;
