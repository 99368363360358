import { useMemo } from "react";
import styles from "./ItemCreator.module.css";

const ItemCreator = ({ propPadding, propZIndex, item }) => {
  const itemCreatorStyle = useMemo(() => {
    return {
      padding: propPadding,
      zIndex: propZIndex,
    };
  }, [propPadding, propZIndex]);

  return (
    <div
      className={styles.itemcreator}
      id="item-creator"
      style={itemCreatorStyle}
    >
      <img className={styles.itemcreatorChild} alt={item.title} src={item.image} />
      <div className={styles.metaverseArtistsParent}>
        <h3 className={styles.metaverseArtists}>{item.title}</h3>
        <blockquote className={styles.learnHowToGuarantee}>
          {item.description}
        </blockquote>
      </div>
    </div>
  );
};

export default ItemCreator;
