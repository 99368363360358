import styles from "./Partners.module.css";
// import avocadoLogo from '../assets/image/avocado_logo.svg';
// import polygonLogo from '../assets/image/polygon_logo.svg';
// import cedimLogo from '../assets/image/cedim_logo.svg';
// import ethernalLogo from '../assets/image/ethernal_logo.svg';
// import AvocadoBlack from '../assets/image/a1.svg';

const Partners = ({ partners }) => {
  return (
    <div className={styles.partners}>
      {partners?.map((partner, index) => (
        <img
          key={index}
          className={styles.icon}
          alt=""
          src={partner.image}
          onClick={() => window.open(partner.url, "_blank")}
          style={{ cursor: 'pointer' }}
        />
      ))}
      {/* <img className={styles.icon} alt="polygon" src={polygonLogo} />
      <img
        className={styles.icon}
        alt="avocado"
        src={avocadoLogo}
      />
      <img
        className={styles.icon}
        alt="cedim"
        src={cedimLogo}
      />
      <img
        className={styles.icon}
        alt="ethernal"
        src={ethernalLogo}
      /> */}
    </div>
  );
};

export default Partners;
