import React, { useEffect, useContext, useState } from "react";
import Header from "../components/Header";
import Section from "../components/Section";
import VideoSection from "../components/VideoSection";
import Benefits from "../components/Benefits";
import { Web3Context } from '../context/Web3AuthContext';
import FAQCard from "../components/FAQs";
import CreatorSection from "../components/CreatorSection";
import FoundersSection from "../components/FoundersSection";
import Footer from "../components/Footer";
import styles from "./FrameComponent.module.css";
import RoadMap from "../components/RoadMap";
import { Drawer, Dialog, Slide, DialogContent, DialogActions, Box } from '@mui/material';
import PanelSelectTypeOfPayment from "../components/PanelSelectTypeOfPayment";
import PanelTermsAndConditions from "../components/PanelTermsAndConditions";
import PanelBuyGamePass from "../components/PanelBuyGamePass";
import background from '../assets/image/background_section.png';
import backgroundMobile from '../assets/image/background_section_mobile.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FrameComponent = () => {
  const { state } = useContext(Web3Context);
  const [openModalPaymentMobile, setOpenModalPaymentMobile] = useState(false);
  const [openModalPaymentDesktop, setOpenModalPaymentDesktop] = useState(false);

  const [openSelectedTypePay, setOpenSelectedTypePay] = useState(1);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const handleCloseModalPaymentMobile = () => {
    if (state.loading) return;
    setOpenModalPaymentMobile(false);
    //setOpenSelectedTypePay(1);
  };

  const handleCloseModalPaymentDesktop = () => {
    if (state.loading) return;
    setOpenModalPaymentDesktop(false);
    //setOpenSelectedTypePay(1);
  };

  const handleCloseAllModal = () => {
    console.log("closing all modal")
    if (state.loading) return;
    setOpenModalPaymentMobile(false);
    setOpenModalPaymentDesktop(false);
    //setOpenSelectedTypePay(1);
  };


  return (
    <div className={styles.headerParent}>
      <Header />
      <Section
        setOpenSelectedTypePay={setOpenSelectedTypePay}
        setOpenModalPaymentMobile={setOpenModalPaymentMobile}
        setOpenModalPaymentDesktop={setOpenModalPaymentDesktop}
      />
      <VideoSection />
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          '@media (max-width: 600px)': {
            backgroundImage: `url(${backgroundMobile})`
          }
        }}
      >
        <Benefits />
        <RoadMap />
        <FAQCard />
      </Box>
      <CreatorSection />
      <FoundersSection />
      <Footer />
      <Dialog
          open={openModalPaymentDesktop}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseModalPaymentDesktop}
          aria-describedby="alert-dialog-slide-description"
          sx={{
              '.MuiPaper-root':{
                  borderRadius: '46px 46px '
              }
          }}
      >
        <DialogContent>
            <DialogActions id="alert-dialog-slide-description">
              {openSelectedTypePay == 1 && (
                <PanelSelectTypeOfPayment
                  setOpenSelectedTypePay={setOpenSelectedTypePay}
                  handleCloseAllModal={handleCloseAllModal}
                />
              )}
              {openSelectedTypePay == 2 && (
                <PanelTermsAndConditions setOpenSelectedTypePay={setOpenSelectedTypePay} />
              )}
              {openSelectedTypePay == 3 && (
                <PanelBuyGamePass
                  handleCloseModalPaymentDesktop={handleCloseModalPaymentDesktop}
                  handleCloseModalPaymentMobile={handleCloseModalPaymentMobile}
                  handleCloseAllModal={handleCloseAllModal}
                />
              )}
            </DialogActions>
        </DialogContent>
      </Dialog>
      <Drawer
          anchor="bottom"
          open={openModalPaymentMobile}
          onClose={handleCloseModalPaymentMobile}
          sx={{
              '&.MuiDrawer-root':{
                  '.MuiDrawer-paper':{
                      borderRadius:' 46px 46px 0px 0px'
                  }
              }
          }}
      >
          <>
            {openSelectedTypePay == 1 && (
              <PanelSelectTypeOfPayment setOpenSelectedTypePay={setOpenSelectedTypePay} handleCloseAllModal={handleCloseAllModal} />
            )}
            {openSelectedTypePay == 2 && (
              <PanelTermsAndConditions setOpenSelectedTypePay={setOpenSelectedTypePay} />
            )}
            {openSelectedTypePay == 3 && (
              <PanelBuyGamePass
                handleCloseModalPaymentDesktop={handleCloseModalPaymentDesktop}
                handleCloseModalPaymentMobile={handleCloseModalPaymentMobile}
                handleCloseAllModal={handleCloseAllModal}
              />
            )}
          </>
      </Drawer>
    </div>
  );
};

export default FrameComponent;
