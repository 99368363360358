import { LOADING_TX_TYPE, GET_TX_DATA_TYPE, ERROR_TX_TYPE } from '../types/types';

export const initialState = {
    loading: null,
    tx: null,
    error: null,
    success: null
};

export function transactionReducer(state, action) {
    switch (action.type) {
        case LOADING_TX_TYPE:
            return {
                ...state,
                loading: action.payload
            };
        case GET_TX_DATA_TYPE:
            return {
                ...state,
                tx: action.payload,
                loading: null,
                error: null
            };
        case ERROR_TX_TYPE:
            return {
                ...state,
                error: action.payload,
                loading: null
            };
        default:
            throw Error('Error to get tx data');
    }
}
