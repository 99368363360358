export const LOADING_TYPE = 'LOADING_TYPE';
export const GET_DATA_TYPE = 'GET_DATA_TYPE';
export const ERROR_TYPE = 'ERROR_TYPE';

// tracsactionReducer.js
export const LOADING_TX_TYPE = 'LOADING_TX_TYPE';
export const GET_TX_DATA_TYPE = 'GET_TX_DATA_TYPE';
export const ERROR_TX_TYPE = 'ERROR_TX_TYPE';

// sanityReducer.js
export const LOADING_SANITY_TYPE = 'LOADING_SANITY_TYPE';
export const GET_SANITY_DATA_TYPE = 'GET_SANITY_DATA_TYPE';
export const ERROR_SANITY_TYPE = 'ERROR_SANITY_TYPE';
