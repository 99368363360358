import React from 'react';
import styles from "./FAQs.module.css";
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import BlockSanity from './Block/BlockSanity';

const FAQItem = ({ question, answer }) => {
    const [ open, setOpen ] = React.useState(false);
  
    const toggleCollapse = () => {
      setOpen(!open);
    }
  
    return (
      <div className={styles.faqsChild} >
      <div className={styles.frameParent} style={open ? {color: "var(--onix-800)"} : {color:'#6C6C6C'}}>
        <div className={styles.whatAreTheFundsBeingUsedParent} onClick={toggleCollapse}>
          <b className={styles.whatAreThe}>
            {question}
          </b>
          <img onClick={toggleCollapse} className={styles.plusIcon} alt="" src={open ? "../minus.svg" : "../plus.svg"} />
        </div>
          <div className={styles.contentCollapsed}>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ width:'100%' }}>
              <div className={styles.paragraph}>
                <BlockSanity text={answer} styleClass={styles.paragraph}  />
              </div>
            </Collapse>
          </div>
        <div className={styles.frameChild} />
      </div>
    </div>
    )
  }

FAQItem.defaultPropTypes = {
  question: 'Question?',
  answer: 'Answer!'
};

FAQItem.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.any
};

export default FAQItem;
