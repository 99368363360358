import { LOADING_SANITY_TYPE, GET_SANITY_DATA_TYPE, ERROR_SANITY_TYPE } from '../types/types';

export const initialState = {
    loading: null,
    sanity: null,
    error: null,
};

export function sanityReducer(state, action) {
    switch (action.type) {
        case LOADING_SANITY_TYPE:
            return {
                ...state,
                loading: action.payload,
                error: null
            };
        case GET_SANITY_DATA_TYPE:
            return {
                ...state,
                sanity: action.payload,
                loading: null,
                error: null
            };
        case ERROR_SANITY_TYPE:
            return {
                ...state,
                error: action.payload,
                loading: null,
                sanity: null
            };
        default:
            throw Error('Error to get sanity data');
    }
}
