import styles from "./CustomButton.module.css";

const CustomButton = ({
  icon,
  text,
  action,
  style = {},
  className = ""
}) => {

  return (
    <button
      className={`${styles.connectwalletbuttom} ${className}`}
      style={style}
      onClick={() => action()}
    >
      {icon && <img className={styles.walletAltIcon} alt="" src={icon} />}
      <b className={styles.actionButton} >
        { text }
      </b>
    </button>
  );
};

export default CustomButton;
