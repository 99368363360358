import styles from "./WhitelistCountdown.module.css";
import { Box } from '@mui/material';
import styled from '@emotion/styled';

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 28px;
  font-family: 'Space Grotesk';
  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const StyledNumber = styled.div`
  font-size: 42px;
  font-weight: 700;
  line-height: 42px;
  font-family: 'Inconsolata';
`;

const StyledNumbertitle = styled.div`
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  font-family: 'Inconsolata';
`;

const WhitelistCountdown = (props) => {
  //BUY FOUNDER GAME PASS NOW
  const { completed } = props.props;
  const { days, hours, minutes, seconds } = props.props.formatted;
  //console.log("is completed: ", completed);
  if (completed) {
    console.log("is completed: ", completed);
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
        <StyledTitle>WHITELIST MINT COUNTDOWN</StyledTitle>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          sx={{
            justifyContent: 'space-around',
            //gap: { xs: '0px', sm: '0px', md: '20px', lg: '20px' },
          }}
        >
          <Box>
            <StyledNumber>00</StyledNumber>
            <StyledNumbertitle>DAYS</StyledNumbertitle>
          </Box>
          <Box>
            <StyledNumber>00</StyledNumber>
            <StyledNumbertitle>HOURS</StyledNumbertitle>
          </Box>
          <Box>
            <StyledNumber>00</StyledNumber>
            <StyledNumbertitle>MINUTES</StyledNumbertitle>
          </Box>
          <Box>
            <StyledNumber>00</StyledNumber>
            <StyledNumbertitle>SECONDS</StyledNumbertitle>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
      <StyledTitle>WHITELIST MINT COUNTDOWN</StyledTitle>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        sx={{
          justifyContent: 'space-around',
          //gap: { xs: '0px', sm: '0px', md: '20px', lg: '20px' },
        }}
      >
        <Box>
          <StyledNumber>{days}</StyledNumber>
          <StyledNumbertitle>DAYS</StyledNumbertitle>
        </Box>
        <Box>
          <StyledNumber>{hours}</StyledNumber>
          <StyledNumbertitle>HOURS</StyledNumbertitle>
        </Box>
        <Box>
          <StyledNumber>{minutes}</StyledNumber>
          <StyledNumbertitle>MINUTES</StyledNumbertitle>
        </Box>
        <Box>
          <StyledNumber>{seconds}</StyledNumber>
          <StyledNumbertitle>SECONDS</StyledNumbertitle>
        </Box>
      </Box>
    </Box>
  );
};

export default WhitelistCountdown;
