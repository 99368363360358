import React, { useContext } from 'react';
import { Web3Context } from '../../context/Web3AuthContext';
import Login from './components/Login';
import LogOut from './components/LogOut/LogOut';
import { CircularProgress, Box } from '@mui/material';
import { WalletContainer, WalletContent } from './styled';
import PropTypes from 'prop-types';

const Wallet = ({ setOpenWallet }) => {
    const { state } = useContext(Web3Context);

    return (
        <WalletContainer>
            {state.loading && (
                <Box display="flex" justifyContent="center" alignItems="center" height="30%" flexDirection="column">
                    <CircularProgress size={50} sx={{ color: '#000' }} />
                    <Box sx={{ color: '#000' }}>Connecting...</Box>
                </Box>
            )}
            <WalletContent>
                {!state.account && !state.loading && <LogOut setOpenWallet={setOpenWallet} />}
                {state.account && !state.loading && <Login setOpenWallet={setOpenWallet} />}
            </WalletContent>
        </WalletContainer>
    );
};

Wallet.propTypes = {
    setOpenWallet: PropTypes.func
};

export default Wallet;
