import styles from "./FounderItem.module.css";

const FounderItem = ({ src, name, job, link="" }) => {
  return (
    <a href={link}  target="_blank" rel="noopener noreferrer" className={styles.frameParent}>
      <div className={styles.screenshot20220402at215Wrapper}>
        <img
          className={styles.screenshot20220402at215Icon}
          alt=""
          src={src}
        />
      </div>
      <div className={styles.nameParent}>
        <b className={styles.name}>{name}</b>
        <div className={styles.name}>{job}</div>
      </div>
    </a>
  );
};

export default FounderItem;
