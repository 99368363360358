import React, { useContext } from 'react';
import { AppBar, Box, Toolbar, CardMedia, Button, Drawer } from '@mui/material';
import walletIcon from '../assets/image/wallet_icon.png';
import { Web3Context } from '../context/Web3AuthContext';
import CountDown from 'react-countdown';
import { SanityContext } from '../context/SanityProvider';
import Wallet from './Wallet';
import { OpenWalletContext } from '../context/OpenWalletProvider';

const Header = () => {
  const { state, resetState } = useContext(Web3Context);
  const { sanity } = useContext(SanityContext);
  const { openWallet, setOpenWallet } = useContext(OpenWalletContext);

  const handleSmoothScrolling = (element, elementToScroll) => {
    const targetElement = document.getElementById(elementToScroll);
    targetElement.scrollIntoView({ behavior: 'smooth' });
  };
  
  const addSmoothScrolling = (linksSelector) => {
    const links = document.querySelectorAll(linksSelector);
  
    links.forEach((link) => {
      const sectionId = link.getAttribute('href').slice(1);
      link.addEventListener('click', (event) => {
        event.preventDefault();
        handleSmoothScrolling(sectionId, sectionId + '-section');
      });
    });
  };

  React.useEffect(() => {
    addSmoothScrolling('.smooth-scroll-link');
  }, []);

  const handleCloseConnectWallet = () => {
    if (state.error) resetState();
    setOpenWallet(false);
  };
  
  return (
    <AppBar position="absolute" sx={{ background: 'transparent', boxShadow:'none', mt: '24px' }}>
      <Toolbar sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }, background: 'transparent' }}>
        <Box flex={1}>
          <CardMedia component="img" src="../brandicon.svg" alt="logo" sx={{ width: '72px', height: '72px' }} />
        </Box>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="32px"
          color="#000"
          sx={{
            fontFamily: 'Inconsolata',
            fontstyle: 'normal',
            fontSize: '16px',
            lineHeight: '17px',
            fontWeight: 700,
          }}
        >
          <Box href="#trailer-section" className="smooth-scroll-link" onClick={() => handleSmoothScrolling('trailer-selector', 'trailer')} sx={{ cursor: 'pointer' }}>Trailer</Box>
          <Box href="#benefits-section" className="smooth-scroll-link" onClick={() => handleSmoothScrolling('benefits-selector', 'benefits')} sx={{ cursor: 'pointer' }}>Benefits</Box>
          <Box href="#roadmap-section" className="smooth-scroll-link" onClick={() => handleSmoothScrolling('roadmap-selector', 'roadmap')} sx={{ cursor: 'pointer' }}>Roadmap</Box>
          <Box href="#faqs-section" className="smooth-scroll-link" onClick={() => handleSmoothScrolling('faqs-selector', 'faqs')} sx={{ cursor: 'pointer' }}>FAQs</Box>
        </Box>
        <Box
          flex={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {sanity.sanity && !sanity.loading && (
            <CountDown
              date={Date.now() + (new Date(`${sanity.sanity.configData.releaseDate}`) - new Date())}
              renderer={props => {
                if (props.completed) {
                  return (
                    <>
                      {!state.account && !state.loading && (
                        <Button
                          type="button"
                          dissableRipple
                          onClick={setOpenWallet}
                          sx={{
                            background: '#282828',
                            borderRadius: '12px',
                            fontFamily: 'Inconsolata',
                            fontstyle: 'normal',
                            fontSize: '16px',
                            lineHeight: '17px',
                            color: '#FFFFFF',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '16px',
                            height: '49px',
                            '&:hover': {
                              background: '#282828'
                            }
                          }}
                        >
                          <CardMedia component="img" src={walletIcon} alt="wallet" sx={{ width: '20px' }} />
                          <Box>Connect Wallet</Box>
                        </Button>
                      )}
                      {state?.loading && (
                        <Button
                          type="button"
                          dissableRipple
                          disable
                          sx={{
                            background: '#282828',
                            borderRadius: '12px',
                            fontFamily: 'Inconsolata',
                            fontstyle: 'normal',
                            fontSize: '16px',
                            lineHeight: '17px',
                            color: '#FFFFFF',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '16px',
                            height: '49px',
                            '&:hover': {
                              background: '#282828',
                            }
                          }}
                        >
                          <CardMedia component="img" src={walletIcon} alt="wallet" sx={{ width: '20px' }} />
                          <Box>Connecting...</Box>
                        </Button>
                      )}
                    </>
                  );
                }}
              }
            />
          )}
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={openWallet} onClose={handleCloseConnectWallet}>
        <Wallet setOpenWallet={setOpenWallet} />
      </Drawer>
    </AppBar>
  );
};

export default Header;
