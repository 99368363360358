import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Box, CardMedia, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Block from '../../Block/Block';

const handleResize = (size) => {
    if(size <= 600){
        return 1;
    }else
    if(size > 600 && size < 700){
        return 2;
    }
    if(size > 699 && size < 1024){
        return 3;
    }else
    if(size > 1023 && size < 2400){
        return 3;
    }else
    if(size > 1023){
        return 4
    }
}

const styleBoxSlide = {
    maxWidth: '440px',
    padding: '24px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
}


const Carousel = ({items}) => {
    let width = window.innerWidth;
    const [ previews, setPreviews ] = useState(handleResize(width));
    useEffect(() => {
        window.addEventListener("resize", function(){
            width = window.innerWidth;
            setPreviews(handleResize(width));
        });
    
        window.removeEventListener("resize",function(){
            width = window.innerWidth;
            setPreviews(handleResize(width));
        });
    },[width]);

    console.log("debug items::",items);


    return (
        <Box 
            sx={{
                width: '100%',
                position: 'relative',
                maxWidth:'1512px',
                margin:'0 auto'
            }}
        >

            <Box component={Swiper}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={previews}
                spaceBetween={30}
                //navigation={{
                //    nextEl: '.custom_next',
                //    prevEl: '.custom_prev'
                //}}
                //autoplay={{
                //    delay: 2500
                //}}
                sx={{ width:'100%', zIndex: 0, marginBottom: '50px',position: 'relative'}}
            >
                {
                    items?.map((item,index)=>{
                        return (
                        <SwiperSlide
                            key={index}
                            style={{
                                maxWidth:previews==1?'calc(100vw - 0px)':'440px',
                                opacity: item && item.isActive ? 1 : 0.6,
                                position: 'relative',

                            }}
                        >
                            <Box 
                                sx={{
                                    position:'absolute',
                                    left:'71px',
                                    top:'48px',
                                    width:'calc(100% - 15px)',
                                    zIndex: 0
                                }}
                            >
                                {/* line */}
                                <Divider sx={{ background:'#C6C6C6'}} />
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="flex-start" gap='8px' sx={styleBoxSlide}>
                                <Box>
                                    <CardMedia component="img" src={item.image} sx={{ width: '48px', height: '48px' }} />
                                </Box>
                                <Box
                                    sx={{
                                        fontFamily: 'Space Grotesk',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '18px',
                                        color: '#000',
                                    }}
                                >
                                    {item.phase}
                                </Box>
                                <Box
                                    sx={{
                                        fontFamily: 'Space Grotesk',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        fontSize: '26px',
                                        lineHeight: '31px',
                                        color: '#000',
                                    }}
                                >
                                    {item.title}
                                </Box>
                                <Box
                                    sx={{
                                        fontFamily: 'Inconsolata',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                        lineHeight: '17px',
                                        color: '#000',
                                    }}
                                >
                                    <Block text={item.description} />
                                </Box>
                            </Box>
                        </SwiperSlide>
                        )
                    })
                }
            </Box>
        </Box>
    );
};

Carousel.propTypes = {
    collection: PropTypes.object
};

export default Carousel;
