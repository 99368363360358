import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url, options = {}, formatter) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(url, { ...options, cancelToken: source.token });
                if (isMounted) {
                    console.log('data::', response.data)
                    setData(formatter(response.data));
                    setError(null);
                }
            } catch (error) {
                if (isMounted) {
                    setData(null);
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(null);
                }
            }
        };
        fetchData();
        console.log('isMounted', isMounted);
        return () => {
            isMounted = false;
            source.cancel('Canceled by user');
        };
    }, [url]);

    return { data, isLoading, error };
};

export default useFetch;
