import React from "react";

function ModelViewerAR({glb}) {
    console.log('GLB ::',glb)
    return (
        <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameborder="0" height="100%" sandbox="allow-scripts" 
        src={"https://openseauserdata.com/frameable-model-viewer.html?model-view-src="+glb} title="3D asset viewer" width="100%"></iframe>
    );
};

export default ModelViewerAR;
