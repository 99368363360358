import React, { useContext } from 'react';
import Social from "./social";
import styles from "./Footer.module.css";
import { SanityContext } from '../context/SanityProvider';

const Footer = () => {
  const { sanity } = useContext(SanityContext);
  if (sanity.sanity && sanity.sanity.footerData) {
    return (
      <div className={styles.frameParent}>
        <div className={styles.socialWrapper}>
          <Social sanity={sanity.sanity.footerData.icons} socialFlexDirection="row" iconFill="white" />
        </div>
        <div className={styles.lyrzLabsOContainer}>
          <span className={styles.lyrzLabsO}>{sanity.sanity.footerData.brandButton}</span>
        </div>
      </div>
    );
  }
  if (sanity.error) {
    return <div className={styles.frameParent}>error to load sanity</div>;
  }
};

export default Footer;
