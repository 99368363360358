import React from 'react';
import PropTypes from 'prop-types';

const Block = ({text, styleClass,padding }) => {
    return (
        <div className={styleClass} 
            style={{
                padding:padding,
                "@media screen and (max-width:500px)":{
                    padding:'0px 0px'
                }
            }}
        >
            {
            text &&
            <div>
                {text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {line !== text[text.length - 1] && <br />}
                </React.Fragment>
                ))}
            </div>
            }
        </div>
    )
};

Block.defaultProps = {
    padding: 'none'
};

Block.propTypes = {
    text: PropTypes.any,
    styleClass: PropTypes.any,
    padding: PropTypes.string
};

export default Block;