import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import FrameComponent from "./pages/FrameComponent";
import { useEffect } from "react";
import Web3AuthContext from "./context/Web3AuthContext";
import OpenWalletProvider from "./context/OpenWalletProvider";
import SanityProvider from "./context/SanityProvider";
import PerseaProvider from "./context/PerseaProvider";
//import { CssBaseLine } from '@mui/material';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <SanityProvider>
      <OpenWalletProvider>
        <PerseaProvider>
          <Web3AuthContext>
          {/* <CssBaseLine /> */}
          <Routes>
            <Route path="/" element={<FrameComponent />} />
          </Routes>
          </Web3AuthContext>
        </PerseaProvider>
      </OpenWalletProvider>
    </SanityProvider>
  );
}
export default App;
