import React, { useContext } from 'react';
import { Box, List, ListItem, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Web3Context } from '../../../../context/Web3AuthContext';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import BlackButton from '../../../Buttons/BlackButton';

const LogOut = ({ setOpenWallet }) => {
    const { state, handleConnectWallet } = useContext(Web3Context);

    const handleConnectMetamask = async () => {
        await handleConnectWallet('metamask');
        console.log(state);
    }

    return (
        <Box sx={{ width: { xs: '100vw', sm: '500px' } }}>
            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center'
                }}
            >
                <ListItem 
                    sx={{
                        width:'100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                    }}
                >
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => setOpenWallet(false)}
                    >
                        X
                    </IconButton>
                </ListItem>
            </List>
            <Box 
                sx={{
                    flex: 1,
                    display: 'flex',
                    width: '100%',
                    minHeight: 'calc(100vh - 70px)',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        padding: '10px'
                    }}
                >
                    {state.error && (
                        <Box
                        sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            flexDirection: 'row',
                            alignItems: 'start',
                            justifyContent: 'flex-end'
                        }}>
                            <ErrorMessage error={state.error}/>
                        </Box>
                    )}
                    <Box component="label" 
                        sx={{
                            fontSize: '24px',
                            fontWeight: '700',
                            lineHeight: '51px',
                            fontFamily: 'Space Grotesk',
                            fontStyle: 'normal'
                        }}
                    >Login In to purchase</Box>
                    <Box component="p"
                        sx={{
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '25px',
                            fontFamily: 'Inconsolata',
                            margin: '0 auto',
                            textAlign: 'center',
                            maxWidth: '340px'
                        }}
                    >Join with your Winter wallet or connect your own address</Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px'
                        }}
                    >
                        <BlackButton
                          icon="../web3.png"
                          text={"Browser Wallet"}  
                          action={handleConnectMetamask}                   
                        />
                        <BlackButton
                          icon="../walletconnect.svg"
                          text={"WalletConnect"}
                          action={()=>{handleConnectWallet('walletConnect')}}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

LogOut.propTypes = {
    setOpenWallet: PropTypes.func
};

export default LogOut;