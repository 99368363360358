export const handleErrors = (error) => {
    console.log('error in handler', error)
    let formatError = {};
    if(error.response) {
        console.log('response', error.response)
        formatError = {
            title : apiErrors[error.response.status],
            status: error.response.status,
            message: error.response.data.message,
        }
    } else if (error.request) {
        console.log('request', error.request)

        formatError = {
            title : apiErrors[error.request.status],
            status: error.request.status,
            message: error.message,
        }
    } else {
        console.log('error',error, apiErrors[error.code])
        let title = apiErrors[error.code]
        let message = ""
        let status = error.code
        if(title && title.includes('Web3')) {
          message = web3Errors[error.code].message
        }else {
          message = error.message
        }
        formatError = {
            title : title ? title : message,
            status: status,
            message: message,
        }
    }
    console.log('format',formatError)
    return formatError
}
export const  apiErrors = {
    '500' : 'Error in your request',
    '400' : 'Error in your request',
    '0' : 'Error in your instance',
    '-32700' : 'Web3 Error',
    '4001' : 'Web3 Error',
    '-32603' : 'Web3 Error',
    '4001' : 'Web3 Error',
    '4902' : 'Web3 Error',
}
export const web3Errors = {
    '-32700': {
      standard: 'JSON RPC 2.0',
      message:
        'Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.',
    },
    '-32603': {
      standard: 'JSON RPC 2.0',
      message:
        'There is temporary issues with the RPC server or network connectivity. It is recommended to try again later.',
    },
    '-32600': {
      standard: 'JSON RPC 2.0',
      message: 'The JSON sent is not a valid Request object.',
    },
    '-32601': {
      standard: 'JSON RPC 2.0',
      message: 'The method does not exist / is not available.',
    },
    '-32602': {
      standard: 'JSON RPC 2.0',
      message: 'Invalid method parameter(s).',
    },
    '-32603': {
      standard: 'JSON RPC 2.0',
      message: 'Internal JSON-RPC error.',
    },
    '-32000': {
      standard: 'EIP-1474',
      message: 'Invalid input.',
    },
    '-32001': {
      standard: 'EIP-1474',
      message: 'Resource not found.',
    },
    '-32002': {
      standard: 'EIP-1474',
      message: 'Resource unavailable.',
    },
    '-32003': {
      standard: 'EIP-1474',
      message: 'Transaction rejected.',
    },
    '-32004': {
      standard: 'EIP-1474',
      message: 'Method not supported.',
    },
    '-32005': {
      standard: 'EIP-1474',
      message: 'Request limit exceeded.',
    },
    '4001': {
      standard: 'EIP-1193',
      message: 'You canceled the operation, please accept to continue.',
    },
    '4100': {
      standard: 'EIP-1193',
      message:
        'The requested account and/or method has not been authorized by the user.',
    },
    '4200': {
      standard: 'EIP-1193',
      message: 'The requested method is not supported by this Ethereum provider.',
    },
    '4900': {
      standard: 'EIP-1193',
      message: 'The provider is disconnected from all chains.',
    },
    '4901': {
      standard: 'EIP-1193',
      message: 'The provider is disconnected from the specified chain.',
    },
  };