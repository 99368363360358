import React, { useContext, useReducer, useState } from 'react';
import styles from "./PanelBuyGamePass.module.css";
import { Web3Context } from '../context/Web3AuthContext';
import { CheckCircle } from '@mui/icons-material';
import { initialState, transactionReducer } from '../web3/reducers/transactionReducer';
import { ERROR_TX_TYPE, GET_TX_DATA_TYPE, LOADING_TX_TYPE } from '../web3/types/types';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import { PerseaContext } from '../context/PerseaProvider';
import { WinterCheckout } from '@usewinter/checkout';
import ModelViewerAR from './ModelViewerAR/ModelViewerAR';
import { CircularProgress, Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import { payableMint } from '../services/blockchain';
import { OpenWalletContext } from '../context/OpenWalletProvider';

const PanelBuyGamePass = ({ handleCloseModalPaymentMobile, handleCloseModalPaymentDesktop, handleCloseAllModal }) => {
    const { state } = useContext(Web3Context);
    const { setOpenWallet } = useContext(OpenWalletContext);
    const {
        nft,
        collection,
        collectionError,
        collectionLoading,
        price,
        priceLoading,
        priceError
    } = useContext(PerseaContext);
    const [transaction, dispatch] = useReducer(transactionReducer, initialState);
    const [ showWinter, setShowWinter ] = useState(false);
    const [loaderWinter, setLoaderWinter] = useState(false);

    const handleClickBuyGamePass = async () => {
        dispatch({ type: ERROR_TX_TYPE, payload: null });
        dispatch({ type: LOADING_TX_TYPE, payload: true });
        try {
            if(state.account) {
                const transaction = await payableMint(
                    getProvider(),
                    getBlockchainName(),
                    getUserWallet(),
                    getCollectionKey(),
                );
                dispatch({ type: GET_TX_DATA_TYPE, payload: transaction.transactionHash });
            } else {
                handleCloseAllModal(true)
                setOpenWallet(true)
            }
        } catch (error) {
            console.log('generado',error);
            dispatch({ type: ERROR_TX_TYPE, payload: error });
        } finally {
            dispatch({ type: LOADING_TX_TYPE, payload: false });
        }
    };

    const getCollectionKey = () => {
        return collection.project_key;
    }

    const getBlockchainName = () => {
        return collection.blockchain.blockchain_name;
    }

    const getProvider = () => {
        return state.account.provider;
    }

    const getUserWallet = () => {
        return state.account.wallet;
    }

    const handleClickExplorer = () => {
        window.open(`${process.env.REACT_APP_SCAN}/tx/${transaction.tx}`, '_blank');
    };

    const handleWinterCheckout = () => {
        if (!nft || collectionError) return;
        setShowWinter(true);
    }

    const handlecloseDrawer = () => {
        handleCloseModalPaymentDesktop();
        handleCloseModalPaymentMobile();
    };

    const getExtraDetails = () => {
        if (!collection) return null;
        if (!collection.extra_details) return null;
        const extra_details = JSON.parse(collection.extra_details);
        if (!extra_details) return null;
        return extra_details;
    }

    const getWinterProduction = () => {
        const extra_details = getExtraDetails();
        if (!extra_details) return null;
        return extra_details.production;
    }

    const getWinterId = () => {
        const extra_details = getExtraDetails();
        if (!extra_details) return null;
        return extra_details.winterId;
    }

    const getNFTName = () => {
        if (!nft) return "You can buy your NFT soon!";
        return nft.metadata.json_data.name.split('#')[0];
    }

    const handleCloseWinter = () => {
        setShowWinter(false);
        handleCloseAllModal();
    }

    //if (state.account) {
        return (
            <div className={styles.panel}>
                <center>
                    <div style={{ marginTop: '50px' }}></div>
                    <div className={styles.iconLyrz}>
                        <img  alt="" src={'../lyrz_black.png'} />
                    </div>
                    <div className={styles.contentFlex}>
                        <div className={styles.contentCard}>
                            <div className={styles.contentNft}>
                                <ModelViewerAR glb={nft.metadata.json_data.animation_url}/>
                            </div>
                        </div>
                        <div className={styles.nftInfo}>
                            <div className={styles.title}>{getNFTName()}</div>
                            <div className={styles.contentAddress}> 
                                <div className={styles.logoWrapper}>
                                    <svg width="27" height="29" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="28" height="28" rx="14" fill="#232323"/>
                                        <path d="M4.31055 11.6352C4.31055 11.4984 4.42249 11.3864 4.5593 11.3864H5.81551C5.95233 11.3864 6.06427 11.4984 6.06427 11.6352V14.9809C6.06427 15.1177 6.1762 15.2297 6.31302 15.2297H8.16624C8.30305 15.2297 8.41499 15.3416 8.41499 15.4784V16.3366C8.41499 16.4734 8.30305 16.5854 8.16624 16.5854H4.5593C4.42249 16.5854 4.31055 16.4734 4.31055 16.3366V11.6352Z" fill="white"/>
                                        <path d="M11.5985 14.4958C11.5238 14.6078 11.4741 14.8192 11.4741 14.956V16.3491C11.4741 16.4859 11.3621 16.5978 11.2253 16.5978H9.96912C9.8323 16.5978 9.72036 16.4859 9.72036 16.3491V14.9312C9.72036 14.7943 9.65817 14.5829 9.59599 14.471L7.87958 11.5979C7.80495 11.4859 7.86714 11.3864 8.00396 11.3864H9.35967C9.49648 11.3864 9.67061 11.4859 9.7328 11.5979L10.5413 12.966C10.6159 13.0779 10.7278 13.0779 10.79 12.966L11.6109 11.5979C11.6855 11.4859 11.8472 11.3864 11.984 11.3864H13.178C13.3149 11.3864 13.3646 11.4859 13.3024 11.5979L11.5985 14.4958Z" fill="white"/>
                                        <path d="M16.0397 15.4909C15.965 15.3789 15.7909 15.2794 15.6665 15.2794C15.5297 15.2794 15.4302 15.3914 15.4302 15.5282V16.3366C15.4302 16.4734 15.3183 16.5854 15.1815 16.5854H13.9128C13.776 16.5854 13.6641 16.4734 13.6641 16.3366V11.6352C13.6641 11.4984 13.776 11.3864 13.9128 11.3864H15.9277C16.0579 11.3864 16.2667 11.3977 16.4046 11.4095C16.4184 11.4107 16.4318 11.4119 16.4456 11.4134C16.5579 11.4253 17.1099 11.4892 17.4327 11.6227C17.7934 11.7844 18.067 12.0083 18.2536 12.3068C18.4526 12.6053 18.5396 12.9536 18.5396 13.3516C18.5396 13.7247 18.4526 14.0481 18.2909 14.3217C18.1765 14.5014 17.9871 14.6704 17.8705 14.7652C17.8038 14.8195 17.722 14.8632 17.6866 14.9416C17.651 15.0201 17.6563 15.1174 17.7063 15.1924L18.5148 16.3864C18.5894 16.4983 18.5396 16.5978 18.4028 16.5978H17.0222C16.8854 16.5978 16.7113 16.5108 16.6367 16.3864L16.0397 15.4909ZM16.5994 12.9038C16.5203 12.8326 16.4261 12.8017 16.368 12.7886C16.3319 12.7804 16.2949 12.7778 16.258 12.7742C16.1216 12.7612 15.9354 12.7421 15.8158 12.7421H15.679C15.5422 12.7421 15.4302 12.8541 15.4302 12.9909V13.7123C15.4302 13.8491 15.5422 13.961 15.679 13.961H15.8158C15.9093 13.961 16.0435 13.9552 16.1628 13.9436C16.2636 13.9338 16.3653 13.9204 16.4592 13.8826C16.5063 13.8637 16.5572 13.8368 16.5994 13.7993C16.7237 13.6874 16.7735 13.5381 16.7735 13.3516C16.7735 13.1526 16.7113 13.0033 16.5994 12.9038Z" fill="white"/>
                                        <path d="M23.5515 15.2297C23.6883 15.2297 23.8002 15.3416 23.8002 15.4784V16.3366C23.8002 16.4734 23.6883 16.5854 23.5515 16.5854H19.2605C19.1237 16.5854 19.0117 16.4734 19.0117 16.3366V15.7521C19.0117 15.6152 19.0863 15.4162 19.1734 15.3167L21.2256 12.9287C21.3127 12.8292 21.2754 12.7421 21.1386 12.7421H19.3351C19.1983 12.7421 19.0863 12.6302 19.0863 12.4934V11.6352C19.0863 11.4984 19.1983 11.3864 19.3351 11.3864H23.4271C23.5639 11.3864 23.6759 11.4984 23.6759 11.6352V12.2197C23.6759 12.3566 23.6012 12.5556 23.5142 12.6551L21.4619 15.0431C21.3749 15.1426 21.4122 15.2297 21.549 15.2297H23.5515Z" fill="white"/>
                                    </svg>
                                </div>
                                <p className={styles.textAddress}>{collection.short_project_key}</p>
                            </div>
                            <div className={styles.contentFee}>
                                {priceError && (
                                    <ErrorMessage error="We couldn't get the price, you can try to refresh the page. If the problem persists, please contact an administrator" />
                                )}
                                {price && !priceLoading && !priceError && (
                                    <>
                                        <img className={styles.Icon} alt="icon" src={'../polygon_black.png'} />
                                        <p className={styles.textFee}>{price}</p>
                                        <div className={styles.textMatic}>Matic</div>
                                    </>
                                )}
                                {priceLoading && (
                                    <span>Loading...</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {!collectionLoading && nft && !collectionError && (
                        <div className={styles.contentButtons} style={{ marginTop: '32px' }}>
                            {(transaction.error && (
                                <div className={styles.contentButtons}>
                                    <ErrorMessage error={transaction.error} />
                                </div>
                            ))}
                            {(transaction.loading && !transaction.error && (
                                <div className={styles.contentButtons}>
                                    Pending transaction...
                                </div>
                            ))}
                            {!transaction.tx && !transaction.loading && !transaction.error && (
                                <button 
                                    onClick={handleClickBuyGamePass}
                                    className={styles.buttonCustomDark}
                                    disabled={transaction.loading}
                                >
                                    <img className={styles.Icon} alt="" src={'../wallet-alt.svg'} />
                                    <b className={styles.textWhite}>
                                        BUY WITH WALLET
                                    </b>
                                </button>
                            )}
                            {transaction.tx && !transaction.loading && !transaction.error && (
                                <>
                                    <span className={styles.title}>Transaction finished!</span>
                                    <CheckCircle color='success' fontSize='large' />
                                    <div className={styles.polygonExplorer} onClick={handleClickExplorer}>
                                        Click here to see the transaction in explorer
                                    </div>
                                    <ButtonClose onClick={handleCloseAllModal} />
                                </>
                            )}
                            {!transaction.tx && !transaction.loading && !transaction.error && Boolean(getWinterId()) && (
                                <button 
                                    onClick={handleWinterCheckout}
                                    className={styles.buttonCustomWhite}
                                    disabled={transaction.loading}
                                >
                                    <img className={styles.Icon} alt="" src={'../file3.svg'} />
                                    <b className={styles.textDark}>
                                        BUY WITH DEBIT/CREDIT CARD
                                    </b>
                                </button>
                            )}
                            {!transaction.tx && !transaction.loading && !transaction.error && (
                                <ButtonCancel
                                    onClick={handleCloseAllModal}
                                    disabled={transaction.loading}
                                />
                            )}
                            {transaction.error && (
                                <ButtonClose onClick={handleCloseAllModal} />
                            )}
                        </div>
                    )}
                    {(collectionLoading || priceLoading) && (
                        <div className={styles.contentButtons}>
                            <div style={{ marginTop: '20px' }}>loading...</div>
                        </div>
                    )}
                    {(!nft || collectionError || priceError) && (
                        <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                            <ButtonCancel
                                onClick={handleCloseAllModal}
                                disabled={transaction.loading}
                            />
                        </div>
                    )}
                    {collectionError && (<ErrorMessage error={collectionError} />)}
                    {priceError && (<ErrorMessage error={priceError} />)}
                    <div style={{ height: '32px' }}></div>
                </center>
                {getExtraDetails() &&
                    <WinterCheckout
                        projectId={getWinterId()}
                        production={getWinterProduction()}
                        showModal={showWinter}
                        extraMintParams={{ quantity : 1 }}
                        onSuccess={() => handleFinishTransaction()}
                        onClose={() => handleCloseWinter()}
                    />
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loaderWinter}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    //};
};

const ButtonCancel = (props) => {
    return (
        <TransparentButton {...props}>
            <b className={styles.textDark}>
                Cancel
            </b>
        </TransparentButton>
    );
}

const ButtonClose = (props) => {
    return (
        <BlackButton {...props}>
            <b className={styles.textWhite}>
                Close
            </b>
        </BlackButton>
    );
}

const BlackButton = ({children, ...props}) => {
    return (
        <button 
            className={styles.buttonCustomDark}
            {...props}>
            {children}
        </button>
    );
}

const TransparentButton = ({children, ...props}) => {
    return (
        <button
            className={styles.buttonCustomWhite}
            style={{border: 0, background: 'transparent'}}
            {...props}>
            <b className={styles.textDark}>
                {children}
            </b>
        </button>
    );
}

PanelBuyGamePass.propTypes = {
    setOpenSelectedTypePay: PropTypes.func,
    handleClickBuyGamePass: PropTypes.func
};

export default PanelBuyGamePass;
