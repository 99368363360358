import React, { useContext } from 'react';
import { Web3Context } from '../../../../context/Web3AuthContext';
import { Box, List, ListItem, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import BlackButton from '../../../Buttons/BlackButton';

const Login = ({ setOpenWallet }) => {
    const { state } = useContext(Web3Context);
    const disconnect = async () => {
        try {
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box sx={{ width: { xs: '100vw', sm: '500px' } }}>
            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center'
                }}
            >
                <ListItem 
                    sx={{
                        width:'100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                    }}
                >
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => setOpenWallet(false)}
                    >
                        X
                    </IconButton>
                </ListItem>
            </List>
            <Box 
                sx={{
                    flex: 1,
                    display: 'flex',
                    width: '100%',
                    minHeight: 'calc(100vh - 70px)',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px'
                        }}
                    >
                        {state.account &&
                        <BlackButton
                            icon="../wallet-alt4.svg"
                            text={"Disconnect"}
                            action={()=>{disconnect()}}
                        />
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

Login.propTypes = {
    setOpenWallet: PropTypes.func
};

export default Login;
