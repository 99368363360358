import styled from '@emotion/styled';

export const WalletContainer = styled.div`
    width: 500px;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
`;

export const WalletContent = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
`;
