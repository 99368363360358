import { LOADING_TYPE, GET_DATA_TYPE, ERROR_TYPE } from '../types/types';

export const initialState = {
    loading: null,
    account: null,
    error: null,
    user: null
};

export function connectionReducer(state, action) {
    switch (action.type) {
        case LOADING_TYPE:
            return {
                ...state,
                loading: action.payload,
                user: null,
                error: null
            };
        case GET_DATA_TYPE:
            return {
                ...state,
                account: action.payload,
                loading: null,
                error: null
            };
        case ERROR_TYPE:
            return {
                ...state,
                error: action.payload,
                loading: null,
                user: null
            };
        default:
            throw Error('Error to get account data');
    }
}
