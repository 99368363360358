import React, {  useContext, useState, useEffect } from 'react';
import Carousel from './Carousel';
import { Box } from '@mui/material';
import DownloadWhitepaperButton from "../Buttons/DownloadWhitepaperButton";
import { SanityContext } from "../../context/SanityProvider";

const RoadMap = () => {

    const [ items, setItems ] = useState([]);
    const [ title, setTitle ] = useState('');
    const { sanity } = useContext(SanityContext);

    useEffect(() => {
        if(sanity && sanity.sanity && sanity.sanity.roadmapData){
            if (sanity.sanity.roadmapData && sanity.sanity.roadmapData.title) {
                setTitle(sanity.sanity.roadmapData.title);
            }
            if (sanity.sanity.roadmapData && sanity.sanity.roadmapData.items && sanity.sanity.roadmapData.items.length > 0) {
                setItems(sanity.sanity.roadmapData.items);
            }
        }
    }, [sanity]);
    
    return (
        <Box sx={{ padding: { xs: '100px 0px 0px', sm: '100px 0px 0px', md: '400px 0px 0px' }, maxWidth: '100%' }}>
            <Box
                id="roadmap"
                sx={{
                    width: '100%',
                    fontFamily: 'Space Grotesk',
                    fontWeight: 700,
                    fontSize: '44px',
                    lineHeight: '56px',
                    color: '#000',
                    textAlign: 'center',
                    marginBottom: '10%',
                    '@media (max-width: 1100px)': {
                        fontSize: '24px',
                        lineHeight: '31px'
                    }
                }}
            >
                {title}
            </Box>
            <Box margin="0 auto">
                <Box sx={{ width:'100%' }}>
                    <Carousel items={items} />
                    <Box
                        sx={{
                            width:'100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '50px 0px 0px'
                        }}
                    >
                        <DownloadWhitepaperButton />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default RoadMap;
