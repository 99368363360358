import React, { useContext } from 'react';
import { SanityContext } from "../../context/SanityProvider";
import GrayButton from "./GrayButton";

const DownloadWhitepaperButton = ({...props}) => {
  const { sanity } = useContext(SanityContext);
  const handleClick = () => {
    window.open(`${sanity.sanity.configData.whitepaperURL}`, "_blank");
  }
  if (sanity.loading) return null;
  return (
    <GrayButton 
      icon="../file3.svg"
      text="DOWNLOAD WHITEPAPER"
      action={() => handleClick()}
      {...props}
    />
  );
}

export default DownloadWhitepaperButton;
