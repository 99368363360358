import React, { useContext, useEffect, useState } from 'react';
import styles from "./PanelSelectTypeOfPayment.module.css";
import { WinterCheckout } from '@usewinter/checkout';
import { Web3Context } from '../context/Web3AuthContext';
import { OpenWalletContext } from '../context/OpenWalletProvider';
import { CircularProgress, Backdrop } from '@mui/material';
import PropTypes from 'prop-types';

const PanelSelectTypeOfPayment = ({ setOpenSelectedTypePay, handleCloseAllModal }) => {
    const { state } = useContext(Web3Context);
    const { setOpenWallet } = useContext(OpenWalletContext);
    const [ showWinter, setShowWinter ] = useState(false);
    const [loaderWinter, setLoaderWinter] = useState(false);
    const handleWinterCheckout = () => {
        //setLoaderWinter(true);
        //setShowWinter(true);
        setOpenSelectedTypePay(2)
        //handleCloseAllModal(true)
      }
    const handleFinishTransaction = () => {
        setShowWinter(false);
        setLoaderWinter(false);
        //setOpenBuy(true);
        //setCurrentPhase(PHASES.fillData);
    }
    const handleCloseWinter = () => {
        setLoaderWinter(false);
        setShowWinter(false);
    }

    const handleConnectWallet = () => {
        setOpenWallet(true);
        handleCloseAllModal();
    };
    useEffect(() => {
        if (state?.account) {
            setOpenSelectedTypePay(2);
        }
    }, [state]);
    return (
        <div className={styles.panel}>
            <center>
                <div className={styles.title}>BUY FOUNDER GAME PASS</div>
                <div className={styles.contentButtons} style={{marginTop: '77px'}}>
                    {!state?.account && !state.loading && (
                        <button type="button" className={styles.buttonCustom} onClick={handleConnectWallet}>
                            <img className={styles.Icon} alt="" src={'../wallet-alt.svg'} />
                            <b className={styles.text}>
                                CONNECT WALLET
                            </b>
                        </button>
                    )}
                    {state?.loading && (
                        <button
                            type="button"
                            className={styles.buttonCustom}
                            //onClick={() => setOpenSelectedTypePay(3)}
                        >
                            <img className={styles.Icon} alt="" src={'../wallet-alt.svg'} />
                            <b className={styles.text}>
                                Connecting...
                            </b>
                        </button>
                    )}
                    <button 
                        type="button"
                        onClick={handleWinterCheckout}
                        className={styles.buttonCustom} 
                        style={{marginTop: '0px'}}
                    >
                        <img className={styles.Icon} alt="" src={'../wallet-alt.svg'} />
                        <b className={styles.text}>
                            PAY WITH CREDIT/DEBIT CARD
                        </b>
                    </button>
                    {/* <button type="button" onClick={handleWinterCheckout}>sadasd</button> */}
                    <button 
                        type="button"
                        onClick={handleCloseAllModal}
                        className={styles.buttonCustom} 
                        style={{marginTop: '0px'}}
                    >
                        <b className={styles.text}>
                            Cancel
                        </b>
                    </button>
                </div>
            </center>
            <WinterCheckout
                projectId={10171}
                production={false}
                showModal={showWinter}
                //extraMintParams={{ quantity : 1, tokenId: nft.tokenId }}
                onSuccess={() => handleFinishTransaction()}
                onClose={handleCloseWinter}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderWinter}
                //onClick={() => setLoaderWinter(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

PanelSelectTypeOfPayment.propTypes = {
    setOpenSelectedTypePay: PropTypes.func,
    handleCloseAllModal: PropTypes.func
};

export default PanelSelectTypeOfPayment;