import React, { useContext } from 'react';
import { SanityContext } from "../context/SanityProvider";
import styles from "./VideoSection.module.css";
//videoURL 
//{sanity.sanity && sanity.sanity.configData?.title}
const VideoSection = () => {
  const { sanity } = useContext(SanityContext);
  return (
    <div id="trailer" className={styles.videoContainer}>
      <iframe  title="about artist" src={sanity.sanity && sanity.sanity.configData?.videoURL} className={styles.video}></iframe>
    </div>
  );
};

export default VideoSection;
