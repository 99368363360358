import { useMemo } from "react";
import styles from "./Social.module.css";
import { useNavigate } from 'react-router-dom';
import  PropTypes  from 'prop-types';

const Social = ({
  socialFlexDirection,
  iconFill,
  sanity,
  ...props
}) => {
  const navigate = useNavigate();
  const handleClickOpenLink = (element) => {
    if (element.isExternal) {
      window.open(element.url, "_blank");
    }
    if (!element.isExternal) {
      navigate(element.url);
    }
  };
  const socialStyle = useMemo(() => {
    return {
      flexDirection: socialFlexDirection,
    };
  }, [socialFlexDirection, iconFill]);

  const colums = sanity?.map((element, id) => {
    return (
      <div key={id} className={styles.sidebarButton} onClick={() => handleClickOpenLink(element)}>
        {/* <Element fill={iconFill} /> */}
        <img src={element.image} alt={element.name} style={{ filter: !iconFill ? 'brightness(0%)' : 'brightness(100%)' }} />
      </div>
    );
  });
  
  return (
    <div className={styles.social} style={socialStyle} {...props}>
      {colums}
    </div>
  );
};

Social.propTypes = {
  socialFlexDirection: PropTypes.string,
  iconFill: PropTypes.string,
  sanity: PropTypes.array
};

export default Social;
