import React, { createContext, useMemo, useReducer, useContext } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import PropTypes from 'prop-types';
import { connectionReducer, initialState } from '../web3/reducers/connectionReducer';
import { LOADING_TYPE, GET_DATA_TYPE, ERROR_TYPE } from '../web3/types/types';
import { addBlockchainNetwork, loginMetamask, loginWalletConnect } from '../services/blockchain';
import { handleErrors } from '../services/messages';
// import { PerseaContext } from './PerseaProvider';

export const Web3Context = createContext(null);

const Web3AuthContext = ({ children }) => {
    const [state, dispatch] = useReducer(connectionReducer, initialState);
    // const {collection, collectionError, collectionLoading} = useContext(PerseaContext);

    const handleConnectWallet = async (type) => {
        console.log("Connection type: ", type);
        dispatch({ type: LOADING_TYPE, payload: true });
        if (type === 'metamask') {
            await connectWithMetamask();
        }
        if (type === 'walletConnect') {
            await connectWithWalletConnect();
        }
    };

    const resetState = () => {
        dispatch({type: ERROR_TYPE, payload: null});
    }

    const connectWithWalletConnect = async () => {
        loginWalletConnect()
            .then((data) => {
                console.info('web3auth is loaded', data)
                dispatch({ type: GET_DATA_TYPE, payload: data });
            })
            .catch((error) => {
                console.error('Error Context', error);
                dispatch({ type: ERROR_TYPE, payload: error.message });
            })
            .finally(() => {
                dispatch({ type: LOADING_TYPE, payload: false });
            });
    }

    const connectWithMetamask = async () => {
        try {
            await connectToSiteBlockchain();
        } catch (error) {
            console.error('Error Context', error);
            dispatch({ type: ERROR_TYPE, payload: error.message });
        }
    }

    const connectToSiteBlockchain = async () => {
        try {
            const metamaskConnection = await loginMetamask();
            console.info('web3auth is loaded', metamaskConnection);
            dispatch({ type: GET_DATA_TYPE, payload: metamaskConnection });
        } catch(error) {
            if (error.code !== 4902) {
                throw error;
            }
            await registerBlokchain();
        }
    }

    const registerBlokchain = async () => {
        try {
            await addSiteBlockchainNetwork();
            await connectToSiteBlockchain();
        } catch (error) {
            const errorConfig = handleErrors(error);
            const newError = new Error();
            newError.title = errorConfig.title;
            newError.message = errorConfig.message;
            newError.code = errorConfig.code;
            dispatch({ type: ERROR_TYPE, payload: newError });
        }
    }

    const addSiteBlockchainNetwork = async () => {
        await addBlockchainNetwork(
            process.env.REACT_APP_ETHEREUM_NETWORK,
            process.env.REACT_APP_NAME_NETWORK,
            [process.env.REACT_APP_RPC],
            [process.env.REACT_APP_SCAN],
            process.env.REACT_APP_CURRENCY_NAME_NETWORK,
            process.env.REACT_APP_CURRENCY_SYMBOL_NETWORK,
            Number(process.env.REACT_APP_CURRENCY_DECIMALS_NETWORK),
            await detectEthereumProvider()
        );
    }

    const wallet = useMemo(() => ({ state, dispatch, handleConnectWallet, resetState }), [state]);
    return (
        <Web3Context.Provider value={wallet}>
            {children}
        </Web3Context.Provider>
    );
};

Web3AuthContext.propTypes = {
    children: PropTypes.node
};

export default Web3AuthContext;
