import { createClient } from '@sanity/client';

const client = createClient({
        projectId: 'h0t3luig',
        dataset: 'production',
        apiVersion: '2022-12-04',
        useCdn: true
});

export const toPlainText = (blocks = []) => {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks
    .join('\n')
}


export const footer = async() => {
    return client.fetch(`*[_type == "footer"] {
        brand,
        icons [] {
            external_link,
            href,
            name,
            new_icon {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let icons = []
        let footer = {
            brandButton : data[0]['brand'],
            icons : []
        }
        for (let index = 0; index <  data[0]['icons'].length; index++) {
            const element = data[0]['icons'][index];
            icons.push({
                isExternal : element.external_link,
                name : element.name,
                url : element.href,
                image : element['new_icon']['asset']['url']
            })
        }
        footer.icons = icons;
        return footer
    }).catch((error) => { throw error})
}

export const faqs = async() => {
    return client.fetch(`*[_type == "faqs"] {
        title,
        description,
        faqs [] {
            faq_title,
            faq_description
        }
    }`).then((data) => {
        let faqs = []
        for (let index = 0; index <  data.length; index++) {
            const element = data[index];
           let faq = {
                title : element.title,
                description: element.description,
                faqs : []
            }
            for (let i = 0; i < element['faqs'].length; i++) {
                const new_faq = element['faqs'][i];
                faq['faqs'].push({
                    description : new_faq.faq_description,
                    title : new_faq.faq_title
                })
            }
           faqs.push(faq)
        }
        return faqs
    }).catch((error) => { throw error})
}

export const founders = async() => {
    return client.fetch(`*[_type == "founders"] {
        icons [] {
            external_link,
            href,
            name,
            description,
            picture {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let founders = []
        for (let index = 0; index <  data[0]['icons'].length; index++) {
            const element = data[0]['icons'][index];
            founders.push({
                isExternal : element.external_link,
                url : element.href,
                name : element.name,
                description : element.description,
                image : element['picture']['asset']['url']
            })
        }
        return founders
    }).catch((error) => { throw error})
}

export const config = async() => {
    return client.fetch(`*[_type == "config"] {
        title,
        image {
            asset->{
                _id,
                url
            }
        },
        description,
        releaseDate,
        whitepaper,
        whitelist,
        show_counter,
        home_button_white,
        home_button_black,
        video,
        terms_and_conditions_title,
        terms_and_conditions,
        icons [] {
            external_link,
            href,
            name,
            new_icon {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let icons = []
        let config = {
            title : data[0]['title'],
            image : data[0]['image']['asset']['url'],
            description : data[0]['description'],
            releaseDate : data[0]['releaseDate'],
            whitepaperURL : data[0]['whitepaper'],
            whitelistURL : data[0]['whitelist'],
            showCounter : data[0]['show_counter'],
            image : data[0]['image']['asset']['url'],
            homeButtonBlackLabel : data[0]['home_button_black'],
            homeButtonWhiteLabel : data[0]['home_button_white'],
            videoURL : data[0]['video'],
            termsAndConditions : {
                title : data[0]['terms_and_conditions_title'],
                description : toPlainText(data[0]['terms_and_conditions'])
            }}
            for (let index = 0; index <  data[0]['icons'].length; index++) {
                const element = data[0]['icons'][index];
                icons.push({
                    isExternal : element.external_link,
                    name : element.name,
                    url : element.href,
                    image : element['new_icon']['asset']['url']
                })
            }
            config.icons = icons;
            return config
        }
    ).catch((error) => { throw error})
}

export const areyoucreatorinfo = async() => {
    return client.fetch(`*[_type == "areyoucreator"] {
        title,
        description,
        launchpadForm,
        items [] {
            title,
            description,
            newIcon {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let items = []
        let areyoucreator = {
            title : data[0]['title'],
            description : data[0]['description'],
            launchpadFormURL : data[0]['launchpadForm'],
            items : []
        }
        for (let index = 0; index <  data[0]['items'].length; index++) {
            const element = data[0]['items'][index];
            items.push({
                title : element.title,
                description : element.description,
                image : element['newIcon']['asset']['url']
            })
        }
        areyoucreator.items = items;
        return areyoucreator
    }).catch((error) => { throw error})
}

export const roadmap = async() => {
    return client.fetch(`*[_type == "roadmap"] {
        title,
        items [] {
            phase,
            title,
            description,
            isActive,
            newIcon {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let roadmap = {
            title : data[0]['title'],
            items : []
        }
        let items = []
        for (let index = 0; index <  data[0]['items'].length; index++) {
            const element = data[0]['items'][index];
            items.push({
                phase : element.phase,
                title : element.title,
                description : element.description,
                isActive : element.isActive,
                image : element['newIcon']['asset']['url']
            })
        }
        roadmap.items = items;
        return roadmap
    }).catch((error) => { throw error})
}


export const benefits = async() => {
    return client.fetch(`*[_type == "benefits"] {
        title,
        description,
        whitepaper,
        items [] {
            title,
            description,
            newIcon {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let items = []
        let benefits = {
            title : data[0]['title'],
            description : data[0]['description'],
            whitepaper : data[0]['whitepaper'],
            items : []
        }
        for (let index = 0; index <  data[0]['items'].length; index++) {
            const element = data[0]['items'][index];
            items.push({
                title : element.title,
                description : element.description,
                image : element['newIcon']['asset']['url']
            })
        }
        benefits.items = items;
        return benefits
    }).catch((error) => { throw error})
}


